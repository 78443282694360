import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { useRef, useState } from 'react'
import ReactCopyToClipboard from 'react-copy-to-clipboard'
import { styled } from 'styled-components'

import { kellyGreenD1, lightgreye3, royalBlue, white } from '../../theme/colors'
import { FlexBox } from '../Base/FlexBox'
import { Text, TextProps } from '../Text/Text'

interface CopyToClipBoardProps {
  text: string
  textProps?: Partial<TextProps>
  children: React.ReactNode
  success?: React.ReactNode
  endIcon?: React.ReactNode
}

// https://stackoverflow.com/a/35443299
function getBGColor(el: Element | null): string {
  if (!el) return ''

  var s = getComputedStyle(el),
    b = s.backgroundColor,
    e = el
  if (
    (b === 'transparent' || b === 'rgba(0, 0, 0, 0)' || b === 'rgba(255,255,255,0)') &&
    e.parentNode !== null
  )
    // parent node will have to be an element node
    b = getBGColor(e.parentNode as Element)
  return b
}

// https://stackoverflow.com/a/13937567
function rgb2hex(_rgb: string) {
  const rgb = _rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i)
  return rgb && rgb.length === 4
    ? '#' +
        ('0' + parseInt(rgb[1], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgb[2], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgb[3], 10).toString(16)).slice(-2)
    : ''
}

function invertColor(hex: string) {
  if (!hex) return 'transparent'

  const color = rgb2hex(hex)
    .replace(/^#/, '')
    .replace(/../g, (color) => {
      return ('0' + parseInt(color, 16).toString(16)).slice(-2)
    })

  if (color.length !== 6) return lightgreye3

  // https://en.wikipedia.org/wiki/Relative_luminance
  // https://contrastchecker.online/color-relative-luminance-calculator/
  // Relative luminance is the relative brightness of any point in a colorspace,
  // normalized to 0 for darkest black and 1 for lightest white. For the sRGB
  // colorspace, the relative luminance of a color is defined as
  // L = 0.2126 * R + 0.7152 * G + 0.0722 * B where R, G and B are defined as:
  //
  // if RsRGB <= 0.03928 then R = RsRGB/12.92 else R = ((RsRGB+0.055)/1.055) ^ 2.4
  // if GsRGB <= 0.03928 then G = GsRGB/12.92 else G = ((GsRGB+0.055)/1.055) ^ 2.4
  // if BsRGB <= 0.03928 then B = BsRGB/12.92 else B = ((BsRGB+0.055)/1.055) ^ 2.4

  // Split color in 3 components (r, g, b), convert to decimal and calculate the luminance
  // if the color is darker than f4f7f9 (bgGray) (0.9259) return FFFFFF (white) (1.0)
  // if the color is lighter than f4f7f9 (bgGray) (0.9259) return E3E8EC (lightgreye3) (0.801)

  const r = parseInt(color.slice(0, 2), 16) / 255
  const g = parseInt(color.slice(2, 4), 16) / 255
  const b = parseInt(color.slice(4, 6), 16) / 255

  const RsRGB = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4)
  const GsRGB = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4)
  const BsRGB = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4)

  const luminance = 0.2126 * RsRGB + 0.7152 * GsRGB + 0.0722 * BsRGB

  if (luminance > 0.93) {
    return lightgreye3
  }

  return white
}

export function CopyToClipBoard({ text, children, success, textProps, endIcon }: CopyToClipBoardProps) {
  const ref = useRef<HTMLDivElement | null>(null)
  const [isCopied, setIsCopied] = useState(false)
  const [visible, setVisible] = useState(false)

  function onCopy() {
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 2000)
  }

  return (
    <ReactCopyToClipboard text={text} onCopy={onCopy}>
      <FlexBox
        ref={(el) => {
          ref.current = el
          if (!visible) setVisible(true)
        }}
      >
        {isCopied ? (
          success || (
            <FlexBox alignItems="center" gap="5px">
              <StyledCheckCircleOutlineOutlinedIcon />
              <Text variant="paragraph2" color={kellyGreenD1} fontWeight={600} {...textProps}>
                Copied
              </Text>
            </FlexBox>
          )
        ) : (
          <span>
            <CopyChildContainer $bgColor={invertColor(getBGColor(ref?.current))}>
              {children}
            </CopyChildContainer>
          </span>
        )}
        {isCopied ? null : endIcon}
      </FlexBox>
    </ReactCopyToClipboard>
  )
}

export function HoverCopyToClipBoard({ text, children }: CopyToClipBoardProps) {
  const [isCopied, setIsCopied] = useState(false)

  function onCopy() {
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 2000)
  }

  return (
    <ReactCopyToClipboard text={text} onCopy={onCopy}>
      <HoverContainer alignItems="center" gap="8px">
        {children}
        {isCopied ? (
          <FlexBox alignItems="center" gap="5px">
            <StyledCheckCircleOutlineOutlinedIcon />
            <Text variant="paragraph2" color={kellyGreenD1} fontWeight={600} mt={0.3}>
              Copied
            </Text>
          </FlexBox>
        ) : (
          <HoverCopyToClipBoardIcon />
        )}
      </HoverContainer>
    </ReactCopyToClipboard>
  )
}

export function CopyToClipBoardIcon({ className, text }: { className?: string; text: string }) {
  return (
    <FlexBox className={className} styles="position: relative; top: -2px; height: 17px;">
      <CopyToClipBoard text={text} success={<StyledCheckCircleOutlineOutlinedIcon />}>
        <StyledFileCopyIcon />
      </CopyToClipBoard>
    </FlexBox>
  )
}

const CopyChildContainer = styled.span<{ $bgColor?: string | null }>`
  display: inline-block;
  cursor: pointer;

  &:hover {
    background-color: ${(p) => p.$bgColor};
    border-radius: 3px;
    padding: 3px 6px;
    margin: -3px -6px;
  }
`

const StyledCheckCircleOutlineOutlinedIcon = styled(CheckCircleOutlineOutlinedIcon)`
  margin-top: -2.4px;
  font-size: 17px;
  color: ${kellyGreenD1} !important;
`

const StyledFileCopyIcon = styled(FileCopyIcon)`
  font-size: 17px;
  cursor: pointer;
  color: ${royalBlue} !important;
`

const HoverContainer = styled(FlexBox)`
  &:hover {
    svg {
      display: block !important;
    }
  }
`

const HoverCopyToClipBoardIcon = styled(StyledFileCopyIcon)`
  display: none;
`
