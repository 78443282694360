import LogRocket from 'logrocket'
import { formattedName } from 'shared/helpers/formatters'

import { detectEnvironment, isProduction } from '../config/config'
import { Team, User } from '../models'
import { camelizeUser } from '../models/user'

export function initAnalytics(user: User, team?: Team) {
  if (isProduction && user?.id) {
    LogRocket.identify(user.id, {
      name: formattedName(camelizeUser(user)),
      email: user.email as string,
      environment: detectEnvironment(),
      bundle_name: team?.product_subscription?.bundle_name ?? '',
      is_vendor_user: Boolean(user.vendor_membership),
    })

    window.analytics.identify(user.id, {
      name: formattedName(camelizeUser(user)),
      email: user.email as string,
      mobile: user.mobile as string,
      first_name: user.first_name,
      last_name: user.last_name,
      team_name: team?.name || 'No Company Created',
      team_id: team?.id ?? '',
      bundle_name: team?.product_subscription?.bundle_name ?? '',
      is_vendor_user: Boolean(user.vendor_membership),
    })
  }

  const branch = (window as any).branch
  if (user?.id && branch) {
    branch.setIdentity(user.id)
  }
}
