import AssignmentIcon from '@material-ui/icons/Assignment'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import PeopleIcon from '@material-ui/icons/People'
import SearchIcon from '@material-ui/icons/Search'
import ViewListIcon from '@material-ui/icons/ViewList'
import AssignmentIconV5 from '@mui/icons-material/Assignment'
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety'
import { forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { ContractorPaymentsIcon } from 'shared/components/Icons/ContractorPaymentsIcon'

import { useHasOldSyncSearchFlag } from '../../../hooks/useHasFeatureFlag'
import { usePermissionFactory } from '../../../hooks/usePermission'
import { Team, User } from '../../../models'
import { PermissionType } from '../../../models/permission'
import { currentTeamSelector } from '../../../redux/selectors/team'
import { currentUserSelector, isVendorUserSelector } from '../../../redux/selectors/users'
import {
  ACCOUNT_ACTIVITY_PATH,
  BROKERS_PROFILE_PATH,
  CONTRACTOR_PAYMENTS_PATH,
  CREDIT_PATH,
  EQUIPMENT_PROFILE_PATH,
  FLEET_MEMBERS_PROFILE_PATH,
  FLEET_PROFILE_PATH,
  JOBS_PATH,
  MARKET_CONDITIONS_PATH,
  PAYMENT_INFORMATION_PROFILE_PATH,
  SAFETY_DASHBOARD_PATH,
  SEARCH_ASYNC_PATH,
  SEARCH_PATH,
  VENDOR_DEDUCTIONS_PATH,
} from '../../../Routes'
import { useShowSideBar } from './useShowSideBar'

export interface SideBarMenuItem {
  label: string
  url: string
  icon: React.ComponentType<any>
  permission?: PermissionType
  enabled?: boolean
  activeUrls?: string[]
}

export function useSideBarMenuItems() {
  const isVendorUser = useSelector(isVendorUserSelector)
  const user = useSelector(currentUserSelector)
  const team = useSelector(currentTeamSelector)
  const hasOldSyncSearchFlag = useHasOldSyncSearchFlag()
  const hasPermission = usePermissionFactory()
  const showSidebar = useShowSideBar()

  if (!showSidebar) return [] as Array<Array<SideBarMenuItem>>

  let sideBarItems: Array<Array<SideBarMenuItem>> = []
  if (isVendorUser) {
    sideBarItems = [
      [
        {
          label: 'Deductions',
          url: VENDOR_DEDUCTIONS_PATH,
          icon: AssignmentIconV5,
        },
      ],
    ]
  } else {
    sideBarItems = [
      [
        {
          label: 'Jobs',
          url: JOBS_PATH,
          icon: AssignmentIcon,
          permission: PermissionType.JOB_LIST,
        },
        {
          label: 'Fleet members',
          url: FLEET_MEMBERS_PROFILE_PATH,
          icon: PeopleIcon,
        },
        {
          label: 'Fleet details',
          url: FLEET_PROFILE_PATH,
          icon: FolderSpecialIcon,
        },
      ],
      [
        {
          label: 'Equipment',
          url: EQUIPMENT_PROFILE_PATH,
          icon: LocalShippingIcon,
          permission: PermissionType.EQUIPMENT_SETUP,
        },
      ],
      [
        {
          label: `Find loads ${hasOldSyncSearchFlag ? 'sync ' : ''}`,
          url: SEARCH_PATH,
          icon: SearchIcon,
          permission: PermissionType.SEARCH,
          enabled: hasOldSyncSearchFlag,
        },
        {
          label: 'Find loads',
          url: SEARCH_ASYNC_PATH,
          icon: SearchIcon,
          permission: PermissionType.SEARCH,
        },
        {
          label: 'Linked load boards',
          url: BROKERS_PROFILE_PATH,
          icon: ViewListIcon,
          permission: PermissionType.BROKER_SETUP,
        },
        {
          label: 'Market insights',
          url: MARKET_CONDITIONS_PATH,
          icon: MarketInsightsIcon,
        },
      ],
      [
        {
          label: 'Safety & compliance',
          url: SAFETY_DASHBOARD_PATH,
          icon: HealthAndSafetyIcon,
          permission: PermissionType.SAFETY_DASHBOARD,
        },
      ],
      [
        {
          label: 'Cash',
          url: getCtCashLandingUrl(team, user),
          icon: AttachMoneyIcon,
          permission: PermissionType.CT_CASH_TAB,
          activeUrls: ['ct-cash'],
        },
        {
          label: 'Contractor payroll',
          url: CONTRACTOR_PAYMENTS_PATH,
          icon: ContractorPaymentsIcon,
          permission: PermissionType.CONTRACTOR_PAYMENTS,
        },
        {
          label: 'Payment information',
          url: PAYMENT_INFORMATION_PROFILE_PATH,
          icon: AssignmentTurnedInIcon,
          permission: PermissionType.PAYMENT_PROFILE,
        },
      ],
    ]
  }

  return sideBarItems
    .map((section) => {
      return section.filter((item) => {
        const isPermissionEnabled = item.permission ? hasPermission(item.permission).enabled : true
        const isEnabled = item.enabled === undefined ? true : item.enabled
        return isPermissionEnabled && isEnabled
      })
    })
    .filter((section) => section.length)
}

export function getCtCashLandingUrl(team: Team, user?: User) {
  if (team.product_subscription.has_factoring_feature) {
    return ACCOUNT_ACTIVITY_PATH
  }

  if (user?.permissions?.ct_cash_credit_tab.enabled) {
    return CREDIT_PATH
  }

  return ACCOUNT_ACTIVITY_PATH
}

const MarketInsightsIcon = forwardRef(function MarketInsightsIcon(
  { className }: { className?: string },
  ref: any
) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      ref={ref}
    >
      <g clipPath="url(#clip0_354_9451)">
        <path d="M14.61 9.26261C13.4325 6.20261 9.24 6.03761 10.2525 1.59011C10.3275 1.26011 9.975 1.00511 9.69 1.17761C6.9675 2.78261 5.01 6.0001 6.6525 10.2151C6.7875 10.5601 6.3825 10.8826 6.09 10.6576C4.7325 9.63011 4.59 8.15261 4.71 7.09511C4.755 6.70511 4.245 6.5176 4.0275 6.84011C3.5175 7.6201 3 8.88011 3 10.7776C3.285 14.9776 6.8325 16.2676 8.1075 16.4326C9.93 16.6651 11.9025 16.3276 13.32 15.0301C14.88 13.5826 15.45 11.2726 14.61 9.26261ZM7.65 13.0351C8.73 12.7726 9.285 11.9926 9.435 11.3026C9.6825 10.2301 8.715 9.18011 9.3675 7.4851C9.615 8.8876 11.82 9.76511 11.82 11.2951C11.88 13.1926 9.825 14.8201 7.65 13.0351Z" />
      </g>
      <defs>
        <clipPath id="clip0_354_9451">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
})
