import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useBreakpoint } from 'shared/hooks/useBreakpoint'

import { detectEnvironment } from '../config/config'
import { Environment } from '../models'
import { currentTeamSelector } from '../redux/selectors/team'
import { currentUserSelector, isVendorUserSelector } from '../redux/selectors/users'

export function useAnalyticsEvent() {
  const isVendorUser = useSelector(isVendorUserSelector)
  const user = useSelector(currentUserSelector)
  const team = useSelector(currentTeamSelector)
  const isSmMobile = useBreakpoint('sm')
  const isXsMobile = useBreakpoint('xs')

  const track = useCallback(
    (event: string, metadata: Record<string, any> = {}, options?: SegmentAnalytics.SegmentOpts) => {
      const _metadata = {
        team_id: team?.id,
        team_name: team?.name || 'No Company Created',
        user_id: user?.id,
        mobile: user?.mobile,
        first_name: user?.first_name,
        last_name: user?.last_name,
        feature_flags: user?.featureFlags,
        bundle_name: team?.product_subscription?.bundle_name,
        composition: team?.composition,
        is_sm_screen: isXsMobile ? false : isSmMobile,
        is_xs_screen: isXsMobile,
        is_vendor_user: isVendorUser,
        ...metadata,
      }

      if (detectEnvironment() !== Environment.PRODUCTION) {
        // eslint-disable-next-line no-console
        console.log(`Tracking analytics event: ${event}, metadata:`, _metadata, options)
      }

      // This should be the only usage if this call
      // eslint-disable-next-line no-restricted-syntax
      window.analytics.track(event, _metadata, options)
    },
    [
      team?.id,
      team?.name,
      user?.id,
      user?.featureFlags,
      user?.mobile,
      user?.first_name,
      user?.last_name,
      isVendorUser,
      team?.product_subscription?.bundle_name,
      team?.composition,
      isXsMobile,
      isSmMobile,
    ]
  )

  return track
}
