import { formatDistance, isEqual, isSameDay } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import Fraction from 'fraction.js'
import { DateTime } from 'luxon'

import { AssignedUser } from '../models/job'
import { Load } from '../models/load'
import { ListUser, User } from '../models/user'
import { toCamelCase } from './utils'

// "San Francisco, CA, USA" -> "San Francisco, CA"
// "Tomahawk, Wisconsin, EE. UU." -> "Tomahawk, Wisconsin"

// only works for USA (and EE. UU. for Spanish-speaking users) which is what we need
export const removeCountryName = (location: string) => {
  if (location.includes('USA')) return location.slice(0, -5)
  if (location.includes('EE. UU.')) return location.slice(0, -9)
  return location
}

const formatCurrencyToIntl = (value: string | number) => {
  if (!Intl?.NumberFormat) {
    return value
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  if (typeof value === 'number') {
    return formatter.format(value)
  }

  return formatter.format(Number(value.replace(/(,|\s)/gi, '')))
}

export const formatCurrency = (
  value: string | number | null,
  { removeDecimals = false }: { removeDecimals?: boolean } = {}
): string | number => {
  if (value == null) return ''
  const formattedValue = formatCurrencyToIntl(value)

  if (!removeDecimals) return formattedValue

  return String(formattedValue).replace('.00', '')
}

export function formatCurrencyToNumberLike(currency?: string) {
  if (!currency) return ''
  return currency?.replace(/,/gi, '')
}

export function formatMonths(months: number) {
  const durationYears = Math.floor(months / 12)
  const durationMonths = months - durationYears * 12
  const durationMonthsLabel = durationMonths
    ? `${durationMonths} month${durationMonths !== 1 ? 's' : ''}`
    : ''

  if (durationYears === 0) return durationMonthsLabel
  return `${durationYears} year${durationYears !== 1 ? 's' : ''} ${durationMonthsLabel}`
}

export const getFormattedDate = (date: string, timezone: string = 'America/Los_Angeles'): any => {
  return DateTime.fromISO(date)
    .setZone(timezone)
    .toLocaleString({ ...DateTime.DATETIME_FULL, hourCycle: 'h23' })
}

export const getHoursFromDurationInHours = (hours: number) => {
  return Math.floor(hours)
}

export const getMinutesFromDurationInHours = (duration: number) => {
  return Math.floor((duration % 1) * 60)
}

export const getFormattedDuration = (duration: number) => {
  const hours = getHoursFromDurationInHours(duration)
  const minutes = getMinutesFromDurationInHours(duration)

  if (hours > 0) {
    return `${hours}h ${minutes}m`
  }
  return `${minutes}m`
}

export const getTimeDifference = (timestamp1: string, timestamp2: string) => {
  const date1 = new Date(timestamp1)
  const date2 = new Date(timestamp2)
  const diff = Math.abs(date2.getTime() - date1.getTime())
  const diffInHours = diff / (1000 * 3600)
  return getFormattedDuration(diffInHours)
}

export const getTimeDifferenceInDays = (timestamp1: string, timestamp2: string) => {
  const date1 = new Date(timestamp1)
  const date2 = new Date(timestamp2)
  const diff = Math.abs(date2.getTime() - date1.getTime())
  const diffInHours = diff / (1000 * 3600 * 24)
  return Math.ceil(diffInHours)
}

export function getTimeDifferenceFromNowInMinutes(dateTime: string) {
  const dateTimeObj = DateTime.fromISO(dateTime)
  const diffObj = dateTimeObj.diffNow(['days', 'hours', 'minutes'])

  return Math.floor(
    Math.abs(diffObj.days) * 24 * 60 + Math.abs(diffObj.hours) * 60 + Math.abs(diffObj.minutes)
  )
}

export const getDateFormattedLong = (date: string, timezone: string) => {
  if (date) {
    const dateObj = utcToZonedTime(date, timezone)
    return format(dateObj, 'E, MMM d, yyyy', { timeZone: timezone })
  }
  return ''
}

export const getFormattedDateRange = (startDate: Date, endDate: Date, timezone: string) => {
  if (startDate && endDate) {
    return `${format(startDate, 'MMM d', { timeZone: timezone })}-${format(endDate, 'MMM d', {
      timeZone: timezone,
    })}`
  }
  return ''
}

export const replaceUnderscore = (input: string) => {
  return input ? input.replace(/_/g, ' ') : ''
}

const MS_PER_MINUTE = 60000
export function timeAgoFromMinutes(minutesAgo: number) {
  const postedDate = new Date(new Date().getTime() - minutesAgo * MS_PER_MINUTE)
  return timeAgo(postedDate)
}

export const timeAgo = (dateTime: string | Date) => {
  return formatDistance(new Date(dateTime), new Date(), { addSuffix: true })
}

export const ctRangeFormatter = (load: Load) => {
  return load?.tripDistanceMi
    ? `$${(load?.estimatedRateMin / load?.tripDistanceMi).toLocaleString('en-US', {
        maximumFractionDigits: 2,
      })} - $${(load?.estimatedRateMax / load?.tripDistanceMi).toLocaleString('en-US', {
        maximumFractionDigits: 2,
      })} /mi`
    : ''
}

export const ctBidRangeFormatter = (min: number, max: number) =>
  `$${min.toLocaleString('en-US')} - $${max.toLocaleString('en-US')}`

export const ctEstimateFormatter = (load: Load) =>
  ctBidRangeFormatter(load!.estimatedRateMin, load!.estimatedRateMax)

export const getStopLocation = (locationCity?: string, locationState?: string) => {
  const city = !!locationCity ? locationCity.toLowerCase() : ''
  const state = !!locationState ? locationState : ''

  if (!!city && !!state) return `${city}, ${state}`
  else if (!!city) return city
  else if (!!state) return state
  else return 'Location not provided'
}

export const getDateTimeWindow = (
  dateStart: string,
  dateEnd: string,
  timezone: string,
  {
    destinationDate,
    alwaysShowTime,
    addBullet,
    addYears,
  }:
    | { destinationDate?: boolean; alwaysShowTime?: boolean; addBullet?: boolean; addYears?: boolean }
    | undefined = {}
) => {
  if (!!dateStart) {
    const dateStartObj = utcToZonedTime(dateStart, timezone)
    const dateEndObj = utcToZonedTime(dateEnd || dateStart, timezone)

    if (!isEqual(dateStartObj, dateEndObj)) {
      //time window is in same day
      if (isSameDay(dateStartObj, dateEndObj)) {
        return `${format(dateStartObj, `MMM d${addYears ? ', yyyy' : ''}${addBullet ? ' • ' : ' '}HH:mm`, {
          timeZone: timezone,
        })} -
         ${format(dateEndObj, 'HH:mm zzz', { timeZone: timezone })}`
      } else if (alwaysShowTime) {
        //time window is a few days
        return `${format(dateStartObj, `MMM d${addYears ? ', yyyy' : ''}${addBullet ? ' • ' : ' '}HH:mm`, {
          timeZone: timezone,
        })} - ${format(dateEndObj, `MMM d${addYears ? ', yyyy' : ''}${addBullet ? ' • ' : ' '}HH:mm zzz`, {
          timeZone: timezone,
        })}`
      } else {
        //time window is a few days
        return `${format(dateStartObj, `MMM d${addYears ? ', yyyy' : ''}${addBullet ? ' • ' : ' '}HH:mm`, {
          timeZone: timezone,
        })} - ${format(dateEndObj, 'MMM d zzz', { timeZone: timezone })}`
      }
    } else
      return format(dateStartObj, `MMM d${addYears ? ', yyyy' : ''}${addBullet ? ' • ' : ' '}HH:mm zzz`, {
        timeZone: timezone,
      })
  } else if (destinationDate) {
    return 'Time to be confirmed'
  }
  return ''
}

export const getDateFormattedShort = (
  date: string | undefined,
  timezone: string | undefined,
  commaSeparator?: boolean
) => {
  const dateFormat = commaSeparator ? 'EEE, MMM d' : 'EEE • MMM d'
  if (date && timezone) {
    const dateObj = utcToZonedTime(date, timezone)
    return format(dateObj, dateFormat, { timeZone: timezone })
  }
  return ''
}

//format mobile in +1(424)574 - 48347 format
export const formatMobile = (mobile: string): string => {
  let result = mobile
  if (!result) {
    return ''
  }
  if (!!mobile && mobile.startsWith('+1')) {
    result = mobile.slice(2)
  }
  return `+1 (${result.substr(0, 3)}) ${result.substr(3, 3)} - ${result.substr(6, 4)}`
}

//get valid mobile value without () and empty spaces
export const convertMobileForAPI = (mobile: string | undefined) => {
  if (!mobile) return ''
  return mobile.replace(/[)( \s_]/g, '')
}

export const setDefaultTimezone = (date: string, timezone: string = 'America/Los_Angeles'): DateTime => {
  return DateTime.fromISO(date).setZone(timezone)
}

export function formatDate(date: string | Date) {
  if (typeof date === 'string' && date.length === 10) {
    date = addNoonToDate(date)
  }

  return new Date(date).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })
}

export function formatShortDate(date: string | Date) {
  if (typeof date === 'string' && date.length === 10) {
    date = addNoonToDate(date)
  }

  return new Date(date).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
  })
}

export function formatShortDateWindow(dateStart: string, dateEnd: string) {
  if (!dateStart && !dateEnd) return ''

  if (dateStart && !dateEnd) return formatShortDate(dateStart)

  if (!dateStart && dateEnd) return formatShortDate(dateEnd)

  const dateStartObj = new Date(dateStart)
  const dateEndObj = new Date(dateEnd)

  if (isSameDay(dateStartObj, dateEndObj)) {
    return formatShortDate(dateStartObj)
  }

  return `${formatShortDate(dateStartObj)} - ${formatShortDate(dateEndObj)}`
}

export function formatDateTime(date: string | Date) {
  if (!date) return ''

  return new Date(date).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
}

export function formatTime(
  date: string | Date,
  { timezone, use24hr }: { timezone?: string; use24hr?: boolean } = {}
) {
  if (!date) return ''

  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: !use24hr,
  }

  if (timezone) {
    options.timeZone = timezone
  }

  return new Date(date).toLocaleTimeString('en-US', options)
}

export function formatTimeWindow(
  dateStart: string,
  dateEnd: string,
  { timezone, use24hr }: { timezone?: string; use24hr?: boolean } = {}
) {
  if (!dateStart && !dateEnd) return ''

  if (dateStart && !dateEnd) return formatTime(dateStart, { timezone, use24hr })

  if (!dateStart && dateEnd) return formatTime(dateEnd, { timezone, use24hr })

  if (dateStart === dateEnd) return formatTime(dateStart, { timezone, use24hr })

  const dateStartObj = new Date(dateStart)
  const dateEndObj = new Date(dateEnd)

  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: !use24hr,
  }

  return `${new Date(dateStartObj).toLocaleTimeString('en-US', options)} - ${new Date(
    dateEndObj
  ).toLocaleTimeString('en-US', { ...options, timeZone: timezone })}`
}

// This will make sure dates without times are rendered as same day across US
export function addNoonToDate(date: string) {
  return new Date(`${date}T12:00:00-0800`)
}

function isNumeric(value: any) {
  return /^-?\d+(\.\d+)?$/.test(value)
}

export const decimalToFraction = (value: any) => {
  // This will convert a decimal to a fraction
  // ie 2.66666667 -> 2 2/3

  if (!value || value === 'NaN') {
    return
  }

  if (!isNumeric(value)) {
    return value
  }

  const fractionString = new Fraction(value).simplify(0.00000001).toFraction(true)
  if (fractionString === 'NaN') {
    return
  }
  return fractionString
}

export const getInitials = (_user?: User | AssignedUser | ListUser) => {
  // return empty string if user unavailable
  if (!_user || !_user.id) return ''
  // TODO: remove toCamelCase when all data is camelCase
  const user = toCamelCase(_user)
  return `${user.firstName[0]}${user.lastName ? user.lastName[0] : ''}`
}

export const getInitialsFromUsername = (userName: string) => {
  const [firstName, lastName] = userName.split(' ')
  return `${firstName[0]}${lastName ? lastName[0] : ''}`
}

export const formattedName = (user: { firstName: string; lastName: string }) => {
  return `${user.firstName} ${user.lastName}`
}

// converts date time string to Month day(number)
export const getDateFormatYearMonth = (date: string | Date) => {
  let dateObj = new Date(date)
  const options: Intl.DateTimeFormatOptions = { month: 'short', year: 'numeric' }
  return dateObj.toLocaleString('en-us', options)
}
