/* eslint-disable @typescript-eslint/naming-convention */

import axios from 'axios'
import * as HttpStatus from 'http-status-codes'
import type { PermissionsCamelCased } from 'shared/models/permission'

import { CDLForm } from '../components/Onboarding/DriverApplication/CDLInformationParser/CDLInformationParser.constants'
import { getVGSDomain } from '../config/config'
import { toCamelCase, toSnakeCase } from '../helpers/utils'
import { Address } from './address'
import { OnboardingBackgroundCheckStatus } from './backgroundCheck'
import { CoreDocument } from './document'
import { EquipmentType } from './equipment'
import { Team } from './team'

export interface ListUser {
  id: string
  firstName: string
  middleName?: string | null
  lastName: string
  mobile?: string
  mobileVerified?: boolean
  dateJoined?: string
  isActive?: boolean
  isStaff?: boolean
  email?: string
  emailVerified?: boolean
  isTeamAdmin?: boolean
  isSuperuser?: boolean
  isTeamDriver?: boolean
  deprecatedIsTeamAdmin?: boolean
  deprecatedIsTeamDriver?: boolean
  lastLogin?: string
  roles?: UserRoles[]
  defaultTeamMembership?: TeamMembership
  shippingAddress: Address | null
  backgroundCheckStatus?: OnboardingBackgroundCheckStatus
}

export interface User extends ListUser {
  driver: Driver
  hasTenstreetDocument?: boolean
  dateCloseAccountRequested?: string
  team?: string
  invitedUser?: Partial<User>
  location?: Location
  featureFlags?: Record<string, boolean>
  intercomIdentity?: string
  searchLoadingMessage?: string
  equipmentTypes?: EquipmentType[]
  permissions?: PermissionsCamelCased
  hadPrivilegeDeniedDetail?: string
  hadPrivilegeSuspendedDetail?: string
  hasEmploymentJobs?: boolean
  hasRecordedTermsOnStripe?: boolean
  employmentJobsDocument?: string
  employmentTenstreetDocument?: string
  employmentReleaseDocument?: string
  pspReportDocuments: Array<{
    url: string
    filename: string
  }>
  dacReportDocuments: Array<{
    url: string
    filename: string
  }>
  vendorMembership?: VendorUser
}

export enum VendorUserStatus {
  APPLYING = 'APPLYING',
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}

export interface VendorUser {
  id: string
  status: VendorUserStatus
  vendor: Vendor
}

export enum VendorStatus {
  APPLYING = 'APPLYING',
  APPROVED = 'APPROVED',
  DEACTIVATED = 'DEACTIVATED',
}

export interface Vendor {
  id: string
  status: VendorStatus
  legalBusinessName: string
  phone: string
  website: string | null
  billingAddress: Address
}

export interface Driver {
  dob?: string
  status?: DriverStatus
  licenseExpiration?: string
  licenseIssued?: string
  licenseNumber?: string
  licenseState?: string
  licenseFront?: string
  licenseBack?: string
  licenseDocument?: CoreDocument<{}> & {
    // TODO fix all uppercase properties
    metadata: {
      DRIVER_LICENSE_ISSUE_DATE: string
      DRIVER_LICENSE_NUMBER: string
      DRIVER_LICENSE_STATE: string
    }
  }
  dateCreated?: string
  dateUpdated?: string
  isDriverApplicationFormComplete?: boolean
  isDriverInfoComplete?: boolean
  isLicenseInfoComplete?: boolean
  isEmergencyContactComplete?: boolean
  dateTermsAccepted?: string
  medicalCard?: string
  medicalCardDocument?: CoreDocument<{}>
  medicalCardExpiration?: string
  isMedicalCardValid?: boolean
  emergencyContact?: EmergencyContact
  shirtSize?: ShirtSize
  yearsDryVan?: number
  yearsFlatbed?: number
  yearsReefer?: number
  hadAtFaultIncident?: boolean
  hadMajorViolation?: boolean
  hadPrivilegeDenied?: boolean
  hadPrivilegeSuspended?: boolean
  hasSsn?: boolean
  isEarlyCycleDriver?: boolean
  checkrCandidateId?: string
  checkrCandidateUrl?: string
  checkrHasActiveConsent?: boolean
  checkrDrugScreeningAppointmentUrl?: string
}

export interface StaffUser {
  id: string
  email: string
  firstName: string
  lastName: string
  middleName: string
}

export interface TeamMembership {
  id: string
  driverStatus: DriverStatus
  driverStatusHuman: DriverStatusHuman
  driverStatusReason?: string
  isActive: boolean
  isTeamAdmin: boolean
  isTeamDriver: boolean
  hasDispatchServiceFeature: boolean
  hasDispatchServiceFreeFeature: boolean
  hasTruckLeaseFeature: boolean
  driverAssignedTruck?: TeamMembershipTruck
}

export interface TeamMembershipTruck {
  dateCreated: string
  dateUpdated: string
  id: string
  licensePlateNumber: string
  licensePlateState: string
  ownershipType: string
  status: string
  vehicleMake: string
  vehicleModel: string
  vehicleNumber: string
  vehicleYear: string
  vinNumber: string
}

export interface EmergencyContact {
  email: string
  mobile: string
  name: string
  relationship: string
}

export interface Location {
  lat: number
  lng: number
  address: string
}

export interface DeviceVersion {
  appBuild: string
  appVersion: string
  deviceModel: string
  deviceType: string
  timestamp: string
  osVersion: string
}

export enum UserRoles {
  BUSINESS_OWNER = 'BUSINESS_OWNER',
  TEAM_ADMIN = 'TEAM_ADMIN',
  DRIVER = 'DRIVER',
}

export const FormattedUserRoles: Record<string, string> = {
  BUSINESS_OWNER: 'Owner',
  TEAM_ADMIN: 'Admin',
  DRIVER: 'Driver',
}

export enum DriverStatus {
  ELIGIBLE = 'ELIGIBLE',
  APPLYING = 'APPLYING',
  ONBOARDING = 'ONBOARDING',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SUSPENDED_TEMPORARILY = 'SUSPENDED_TEMPORARILY',
  DEACTIVATED = 'DEACTIVATED',
}

export enum DriverStatusHuman {
  ELIGIBLE = 'Eligible',
  APPLYING = 'Applying',
  ONBOARDING = 'Onboarding',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  SUSPENDED_TEMPORARILY = 'Suspended Temporarily',
  DEACTIVATED = 'Deactivated',
}

export const DriverStatusToHumanReadable: Record<string, string> = {
  ACTIVE: 'Unsuspended',
  SUSPENDED_TEMPORARILY: 'Suspended',
  DEACTIVATED: 'Deactivated',
}

export const ActiveDriverStatuses = [
  DriverStatus.ELIGIBLE,
  DriverStatus.APPLYING,
  DriverStatus.ONBOARDING,
  DriverStatus.ACTIVE,
  DriverStatus.SUSPENDED_TEMPORARILY,
]

export enum ShirtSize {
  SMALL = 'S',
  MEDIUM = 'M',
  LARGE = 'L',
  X_LARGE = 'XL',
  XX_LARGE = 'XXL',
  XXX_LARGE = 'XXXL',
}

// API

export interface DriverPayload {
  // licenseExpiration?: string
  // licenseNumber?: string
  // licenseState?: string
  // licenseIssued?: string
  // dob?: string
  // ssnEncrypted?: string
  // dateTermsAccepted?: string
  // yearsDryVan?: number
  // yearsFlatbed?: number
  // yearsReefer?: number
  // hadAtFaultIncident?: boolean
  // hadMajorViolation?: boolean
  // hadPrivilegeDenied?: boolean
  // hadPrivilegeSuspended?: boolean
  // hadPrivilegePayload?: boolean
}

export interface UserPayload {
  // firstName?: string
  // lastName?: string
  mobile?: string
  email?: string
  // isTeamDriver?: boolean
  // driver?: DriverPayload
  // isTeamAdmin?: boolean
  shippingAddress?: Address
  // hadPrivilegeDeniedDetail?: string
  // hadPrivilegeSuspendedDetail?: string
}

export function getLoggedInUser(): Promise<{ user: User; team?: Team }> {
  const url = `/api/v3/users/me/?slim_member=true`
  return axios.get(url).then((response) => {
    const { team_object, ...user } = response.data || {}

    return {
      user: toCamelCase(user),
      team: toCamelCase(team_object),
    } as { user: User; team?: Team }
  })
}

export function getUser(user_id: string): Promise<{ user: User; team?: Team }> {
  const url = `/api/v3/users/${user_id}/?slim_member=true`
  return axios.get(url).then((response) => {
    const { team_object, ...user } = response.data || {}

    return {
      user: toCamelCase(user),
      team: toCamelCase(team_object),
    } as { user: User; team?: Team }
  })
}

export function updateUser(
  user_id: string,
  payload: UserPayload,
  options: { vgs?: boolean } = {}
): Promise<User> {
  const url = `${options.vgs ? getVGSDomain() : ''}/api/v3/users/${user_id}/`
  const patchOptions = options.vgs ? { withCredentials: true } : undefined
  return axios
    .patch(url, toSnakeCase(payload), patchOptions)
    .then((response) => toCamelCase(response.data) as User)
}

export function updateEmergencyContactInfo(
  userId: string,
  emergencyContact: EmergencyContact
): Promise<User> {
  const url = `/api/v3/users/${userId}/emergency-contact/`
  return axios.post(url, toSnakeCase(emergencyContact)).then((response) => toCamelCase(response.data) as User)
}

interface RevealSsn {
  ssnEncrypted: string
}

export function revealSsn(userId: string): Promise<RevealSsn> {
  const url = `${getVGSDomain()}/api/v3/users/${userId}/ssn/reveal/`
  return axios
    .post(url, null, { withCredentials: true })
    .then((response) => toCamelCase(response.data) as RevealSsn)
}

export function updateSsn(userId: string, payload: { ssn: string }) {
  const url = `${getVGSDomain()}/api/v3/users/${userId}/ssn/`
  return axios
    .post(url, { ssn_encrypted: payload.ssn }, { withCredentials: true })
    .then((response) => toCamelCase(response.data) as User)
}

export interface UpdateUserPayload {
  first_name?: string
  middle_name?: string | null
  last_name?: string
  mobile?: string
  is_team_driver?: boolean
  driver?: DriverPayload
  is_team_admin?: boolean
  shipping_address?: Address
  had_privilege_denied_detail?: string
  had_privilege_suspended_detail?: string
  cdl_update?: boolean
}

export function updateUserInfo(
  user_id: string,
  payload: UpdateUserPayload,
  options: { vgs?: boolean; v3Api?: boolean } = {}
): Promise<User> {
  const url = `${options.vgs ? getVGSDomain() : ''}/api/v${options.v3Api ? '3' : '1'}/users/${user_id}/`
  const patchOptions = options.vgs ? { withCredentials: true } : undefined
  return axios
    .patch(url, payload, patchOptions)
    .then((response) => {
      if (response.status === 200) {
        return response.data
      }
      throw response.data
    })
    .catch((error) => {
      throw error?.response?.data || error?.response || error
    })
}

export interface UploadMedicalCardPayload {
  userId: string
  formData: FormData
}

export function saveMedicalCard(payload: UploadMedicalCardPayload): Promise<User> {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  const url = `/api/v3/users/${payload.userId}/medical-card/`
  return axios.post(url, payload.formData, config).then((response) => {
    if (response.status === HttpStatus.OK) {
      return response.data
    }
    throw response.data
  })
}

type CDLParserResponse = {
  first_name?: string
  middle_name?: string
  last_name?: string
  date_of_birth?: string
  zip_code?: string
  address_1?: string
  address_2?: string
  city?: string
  state?: string
  license_number?: string
  date_expired?: string
  date_issued?: string
  license_class?: string
}

const transformCDLParserResponse = (data: CDLParserResponse): CDLForm => {
  return {
    firstName: data.first_name ?? '',
    middleName: data.middle_name ?? '',
    lastName: data.last_name ?? '',
    dob: data.date_of_birth ?? '',
    licenseNumber: data.license_number ?? '',
    licenseState: data.state ?? '',
    licenseIssued: data.date_issued ?? '',
    licenseExpiration: data.date_expired ?? '',
    address: {
      line1: data.address_1 ?? '',
      line2: data.address_2 ?? '',
      city: data.city ?? '',
      state: data.state ?? '',
      zip: data.zip_code ?? '',
    },
  }
}

export function uploadCDLParserParse(userId: string, payload: FormData): Promise<CDLForm> {
  const url = `/api/v3/users/${userId}/parse-license/`
  return axios
    .post(url, payload)
    .then((response) => {
      return transformCDLParserResponse(response.data)
    })
    .catch((error) => {
      return error.response.data
    })
}

export function generateReleaseForm(userId: string) {
  let url = `/api/v3/users/${userId}/generate-release-form/`
  return axios.post(url).then((response) => {
    return response.data
  })
}

export function generateEmploymentJobsDocument(userId: string) {
  let url = `/api/v3/users/${userId}/generate-employment-jobs-document/`
  return axios.post(url).then((response) => {
    return response.data
  })
}

export function acceptDriverTerms(userId: string): Promise<void> {
  return axios.post(`/api/v3/users/${userId}/accept-terms/`)
}

export function notifyUserToSignAgreement(userId: string, sendAlsoSms = false): Promise<void> {
  return axios.post(
    `/api/v3/users/${userId}/notify-sign-agreement/${sendAlsoSms ? '?send-also-sms=true' : ''}`
  )
}

export function getDeviceVersions(userId: string): Promise<DeviceVersion[]> {
  return axios
    .get(`/api/v3/users/${userId}/device-versions/`)
    .then((response) => toCamelCase(response.data) as DeviceVersion[])
}
